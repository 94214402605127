import { Theme, useTheme } from '@mui/material';

function MediaIcon({ color }: { color: string | ((theme: Theme) => string) }) {
  const theme = useTheme();
  const fillColor = typeof color === 'function' ? color(theme) : color;
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_4757_44226"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <rect width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_4757_44226)">
        <path
          d="M8 18L2 12L8 6L9.425 7.425L4.825 12.025L9.4 16.6L8 18ZM16 18L14.575 16.575L19.175 11.975L14.6 7.4L16 6L22 12L16 18Z"
          fill={fillColor}
        />
      </g>
    </svg>
  );
}

export default MediaIcon;
