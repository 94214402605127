import { Theme, useTheme } from '@mui/material';

function ArrowCircleUpIcon({
  color,
}: {
  color: string | ((theme: Theme) => string);
}) {
  const theme = useTheme();
  const fillColor = typeof color === 'function' ? color(theme) : color;
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      shapeRendering="geometricPrecision"
    >
      <mask
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="32"
        height="32"
      >
        <rect width="32" height="32" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_3826_38732)">
        <path
          d="M14.8893 15.1555V19.9555C14.8893 20.274 14.9949 20.5387 15.206 20.7498C15.4171 20.9609 15.6819 21.0665 16.0003 21.0665C16.3188 21.0665 16.5835 20.9609 16.7947 20.7498C17.0058 20.5387 17.1113 20.274 17.1113 19.9555V15.1555L18.6893 16.7332C18.904 16.9481 19.1632 17.0555 19.467 17.0555C19.7708 17.0555 20.03 16.9481 20.2447 16.7332C20.4595 16.5183 20.567 16.2591 20.567 15.9555C20.567 15.6517 20.4595 15.3924 20.2447 15.1775L16.778 11.7108C16.5558 11.4886 16.2965 11.3775 16.0003 11.3775C15.7041 11.3775 15.4449 11.4886 15.2227 11.7108L11.756 15.1775C11.5411 15.3924 11.4337 15.6517 11.4337 15.9555C11.4337 16.2591 11.5411 16.5183 11.756 16.7332C11.9707 16.9481 12.2299 17.0555 12.5337 17.0555C12.8374 17.0555 13.0967 16.9481 13.3113 16.7332L14.8893 15.1555ZM16.0003 29.3332C14.1708 29.3332 12.4449 28.9832 10.8227 28.2832C9.20044 27.5832 7.78555 26.6295 6.57799 25.4222C5.37066 24.2146 4.41699 22.7997 3.71699 21.1775C3.01699 19.5553 2.66699 17.8294 2.66699 15.9998C2.66699 14.1554 3.01699 12.4221 3.71699 10.7998C4.41699 9.17762 5.37066 7.7665 6.57799 6.5665C7.78555 5.3665 9.20044 4.4165 10.8227 3.7165C12.4449 3.0165 14.1708 2.6665 16.0003 2.6665C17.8448 2.6665 19.5781 3.0165 21.2003 3.7165C22.8225 4.4165 24.2337 5.3665 25.4337 6.5665C26.6337 7.7665 27.5837 9.17762 28.2837 10.7998C28.9837 12.4221 29.3337 14.1554 29.3337 15.9998C29.3337 17.8294 28.9837 19.5553 28.2837 21.1775C27.5837 22.7997 26.6337 24.2146 25.4337 25.4222C24.2337 26.6295 22.8225 27.5832 21.2003 28.2832C19.5781 28.9832 17.8448 29.3332 16.0003 29.3332Z"
          fill={fillColor}
        />
      </g>
    </svg>
  );
}

export default ArrowCircleUpIcon;
