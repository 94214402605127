import { Zen_Kaku_Gothic_Antique, Noto_Sans } from 'next/font/google';
import localFont from 'next/font/local';

export const copernicusBold = localFont({
  src: './fonts/Copernicus-Bold.ttf',
  display: 'swap',
});

export const copernicus = localFont({
  src: './fonts/GalaxieCopernicus-Book.ttf',
  display: 'swap',
});

export const zenKakuGothicAntique = Zen_Kaku_Gothic_Antique({
  weight: '500',
  subsets: ['latin'],
});

export const notoSans = Noto_Sans({
  weight: '400',
  subsets: ['latin'],
});

export const notoSansBold = Noto_Sans({
  weight: '700',
  subsets: ['latin'],
});

export const polaris = localFont({
  src: './fonts/Galaxie Polaris Medium.otf',
  display: 'swap',
});

export const polarisBold = localFont({
  src: './fonts/galaxiepolaris-bold.otf',
  display: 'swap',
});

export function applyFont(
  font: ReturnType<typeof localFont>,
  applyWeight = true
) {
  return {
    fontFamily: font.style.fontFamily,
    fontStyle: font.style.fontStyle,
    fontWeight: applyWeight ? font.style.fontWeight : undefined,
  };
}
